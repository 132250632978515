import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import PersonalPortfolio from './home/PersonalPortfolio';
import { BrowserRouter, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter>
              <Route exact path={"/"} component={PersonalPortfolio}/>  
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();
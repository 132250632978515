import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>I was privileged to receive an exceptional artwork from Joevan. I was impressed with his design expertise and attention to detail. I highly recommend Joevan’s services for outstanding graphic work. Thank you Joevan, Keep going!</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Jabbor Kayumov </span> - Former CEO Digicel Jamaica Ltd.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p> Joevan has been one of the best developer I had the pleasure to work with. I would suggest a concept and he would exceed my expectations. He was instrumental in the launch of Digicel’s Customer Care communication hub </p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Stephen Rodriguez</span> -  Former Director of Customer Operations – Digicel Jamaica Ltd.</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>The way Joey listens to my ideas on a project and bring it to life and further beautify it with color is just next level, keep pushing my brother </p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Sherlon Bryan</span> - CEO for Pogpo Energy Music Record Label</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>A talented designer that gets the job done and listens. Has keen knowledge on not only design stuff but also how the design will communicate with other functions of a business and further cause expected reactions in people</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Okice Davis</span> - Founder of Umbrella Evening brunch (NMG Ent.)</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>“ Known this guy for over 2 decades and he has always been that creative genius ever since, just more better now. He personally does my music videos and cover arts and the trust I have is the result of the hard work he has put in over the years ”</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Junior Thompson aka Ballany </span> -  Recording Artiste</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/Jabbor Kayumov.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/Stephen Rodriguez.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/Sherlon Bryan.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/Okice davis.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/Ballany.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;
import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <>
                <Helmet>
                    <title>{this.props.pageTitle}</title>
                    <meta name="description" content="Joevan Duhaney Personal Portfolio Site" />
                </Helmet>
            </>
        )
    }
}


export default PageHelmet;

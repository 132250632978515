import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab2 = "Experience",
        tab3 = "Education",
        tab4 = "Current Interests";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">The Graphic Design experience <span> - UI/UX</span></a>
                                                    "Designing impactful visual experiences through a creative use of typography, color, and composition is my passion in graphic design."
                                                </li>
                                                <li>
                                                    <a href="/service">Photography and Cinematography<span></span></a>
                                                    "Crafting stunning visual narratives that freeze moments in time and evoke emotions is my signature approach to photography and cinematography."
                                                </li>
                                                <li>
                                                    <a href="/service">Visual Arts<span></span></a>
                                                   "Bringing imagination to life through the seamless blend of color, form, and texture is my artistic approach to visual arts."
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                        
                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Acting System support Specialist (multimedia)<span> -  Digicel</span></a> 2022 - Current
                                               </li>
                                               <li>
                                                   <a href="/service">Creative Director/Graphic Designer <span> - Creative Director/Graphic Designer </span></a> Jan 2017 - Present
                                               </li>
                                               <li>
                                                   <a href="/service">Co-founder/Creative Director/Recording Artiste <span> - Metro Mixx Records</span></a> 2014 - Present
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service"> Asc in Information and Communication Technology<span> -  – Vocational
                                                          Training Development Institute, Kingston, Jamaica</span></a> - 2022 - Present
                                               </li>
                                               <li>
                                                   <a href="/service"> Asc in Engineer Technology <span> - Portmore Community College</span></a> 2013 - 2015
                                               </li>
                            
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                               Advanced 2D/3D Animation
                                               </li>
                                               <li>
                                               Computer Programming
                                               </li>
                                               <li>
                                               3D Game development
                                               </li>
                                               
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;
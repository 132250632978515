import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import {Link} from "react-router-dom";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../component/header/HeaderTwo";
import FooterTwo from "../component/footer/FooterTwo"
import Brand from "../elements/Brand";
import PortfolioList from "../elements/portfolio/PortfolioList";
import TabTwo from "../elements/tab/TabTwo";
import ContactOne from "../elements/contact/ContactOne";
import Helmet from "../component/common/Helmet";
import Testimonial from "../elements/Testimonial";



const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Freelance Digital Designer',
        title: 'Hey, I’m <span>Joevan</span> Welcome to my Galaxy.',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]


class PersonalPortfolio extends Component{
    render(){
        let title = 'About Me',
        description = 'As a versatile and multi-talented creative, I offer a unique blend of skills in digital design, photography, cinematography, and visual arts. With a strong background in visual communication and brand development, I bring a fresh perspective and eye for detail to every project I undertake.My passion for creativity extends beyond my professional pursuits, as I am also a recording artist, adding another layer of depth and artistry to my work. My goal is to create visually stunning and impactful designs that effectively communicate my clients visions and messages.';
        return(
            <Fragment> 
                <Helmet pageTitle="Joey Duhaney Portfolio" />
                <HeaderTwo logo="symbol-dark" color="color-black"/>
                
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map(({category,title,description,buttonText,buttonLink,textPosition}, index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--16" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${textPosition}`}>
                                            {category ? <span>{category}</span> : ''}
                                            {title ? <h1 className="title" dangerouslySetInnerHTML={{__html: title}}></h1> : ''}
                                            {description ? <p className="description">{description}</p> : ''}
                                            {buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${buttonLink}`}>{buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-5.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner pt--100">
                                        <div className="section-title" id="about">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">Brand Collaboration</h2>
                                    <p>Joevan has had the privilege of working with a number of well-known companies and people, delivering exceptional design solutions and elevating their brand. His passion for design, combined with his creative problem-solving skills, has enabled him to deliver unique and effective designs for clients. Some of the companies he has collaborated with include Digicel Sports & Social Club, HR Concepts, and Global
Music Entertainment, NMG Entertainment, etc. Whether it was creating a new brand identity, designing marketing materials, or developing digital experiences, He has consistently exceeded the clients' expectations and helped them achieve their goals.
With his deep understanding of design principles, attention to detail, and commitment to delivering results, he is confident he can add value to any organization and help them stand out in their industry. Let's work together to bring your brand to life.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--40 mt_sm--5 mt_md--5">
                                <Brand branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--60 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                        <div className="container" id="projects">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">My Latest Project</h2>
                                        <p>Projects That I have worked on. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        {/* <a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Portfolio Area */}

                 {/* Start Testimonial */}
                 <div className = "testimonial-area pb--120">
                    <Testimonial />
                 </div>
                    
                  {/* End Testimonial */}
                <div className="portfolio-area pb--120 bg_color--1">
                    <ContactOne />
                </div>
                {/* End Portfolio Area */}

                <FooterTwo />

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default PersonalPortfolio;